<template>
  <div class="e-wallet">
    <div class="banner">
      <div class="container">
        <strong>{{ $t(`EWallet['您最优质的支付选择']`) }}</strong>
        <p>
          {{ $t(`EWallet['YOUR BEST CHOICE OF PAYMENT']`) }}
        </p>
      </div>
    </div>
    <Penel
      id="user"
      :title="$t(`EWallet['立即成为U-Pay用户']`)"
      class="become common"
    >
      <ul class="download">
        <li>
          <a href="#">
            <img
              src="@/assets/image/home/icon-Appstore@2x.png"
              alt="Appstore upay app"
            />
          </a>
        </li>
        <li>
          <a href="#">
            <img
              src="@/assets/image/home/icon-Android@2x.png"
              alt="Android upay app"
            />
          </a>
        </li>
      </ul>
      <ul class="use-steps">
        <li v-for="(item, i) in useSteps" :key="i">
          <template v-if="i % 2 === 0">
            <img :src="item[$i18n.locale + 'Img']" alt="upay use steps" />
            <strong>{{ $t(`Common['${item.topic}']`) }}</strong>
            <p>{{ $t(`EWallet['${item.text}']`) }}</p>
          </template>
          <template v-if="i % 2 === 1">
            <div class="arrow">
              <img src="@/assets/image/ewallet/红色箭头@2x.png" alt="" />
            </div>
          </template>
        </li>
      </ul>
    </Penel>
    <Penel
      style="display: none"
      :title="$t(`EWallet['如何充值您的钱包']`)"
      class="rechargesteps common"
    >
      <ul class="use-steps">
        <li v-for="(item, i) in rechargeSteps" :key="i">
          <template v-if="i % 2 === 0">
            <img :src="item.img" alt="" />
            <strong>{{ $t(`Common['${item.topic}']`) }}</strong>
            <p>{{ $t(`EWallet['${item.text}']`) }}</p>
          </template>
          <template v-if="i % 2 === 1">
            <div class="arrow">
              <img src="@/assets/image/ewallet/红色箭头@2x.png" alt="" />
            </div>
          </template>
        </li>
      </ul>
    </Penel>
    <Penel :title="$t(`EWallet['如何缴费']`)" class="paySteps common">
      <ul class="use-steps">
        <li v-for="(item, i) in paySteps" :key="i">
          <template v-if="i % 2 === 0">
            <img :src="item[$i18n.locale + 'Img']" alt="upay pay steps" />
            <strong>{{ $t(`Common['${item.topic}']`) }}</strong>
            <p>{{ $t(`EWallet['${item.text}']`) }}</p>
          </template>
          <template v-if="i % 2 === 1">
            <div class="arrow">
              <img src="@/assets/image/ewallet/红色箭头@2x.png" alt="" />
            </div>
          </template>
        </li>
      </ul>
    </Penel>
    <Penel id="invite" :title="$t(`EWallet['邀请好友']`)" class="common">
      <p style="text-align: center">
        {{
          $t(
            `EWallet['${"超“友”礼！邀请朋友注册，即获奖金！朋友消费，您还可获得每月高达2%的现金反馈！"}']`
          )
        }}
      </p>
      <ul class="use-steps">
        <li v-for="(item, i) in inviteSteps" :key="i">
          <template v-if="i % 2 === 0">
            <img :src="item[$i18n.locale + 'Img']" alt="upay pay steps" />
            <strong>{{ $t(`Common['${item.topic}']`) }}</strong>
            <p>{{ $t(`EWallet['${item.text}']`) }}</p>
          </template>
          <template v-if="i % 2 === 1">
            <div class="arrow">
              <img src="@/assets/image/ewallet/红色箭头@2x.png" alt="" />
            </div>
          </template>
        </li>
      </ul>
    </Penel>
    <Penel id="guide" :title="$t(`EWallet['使用说明']`)" class="instructions">
      <el-collapse class="main" v-for="i in 7" :key="i">
        <el-collapse-item>
          <template slot="title">
            <div class="title-text">
              {{ $t(`EWallet['FAQ_ForUser_problem_${i}']`) }}
            </div>
          </template>
          <div
            class="solution_text"
            v-html="$t(`EWallet['FAQ_ForUser_solution_${i}']`)"
          ></div>
        </el-collapse-item>
      </el-collapse>
    </Penel>
  </div>
</template>

<script>
export default {
  name: 'EWallet',
  computed: {
    useSteps() {
      return [
        {
          zhImg: require('../../assets/image/ewallet/注册步骤-CN/CN-注册1.png'),
          enImg: require('../../assets/image/ewallet/注册步骤- EN/EN- 注册1.png'),
          khImg: require('../../assets/image/ewallet/注册步骤-KH/KH-注册1.png'),
          topic: 'Step 1',
          text:
            '至APP Store / Play Store 下载完成后，打开App 输入您的个人资讯（电话号码/姓名/性别）'
        },
        {},
        {
          zhImg: require('../../assets/image/ewallet/注册步骤-CN/CN-注册2.png'),
          enImg: require('../../assets/image/ewallet/注册步骤- EN/EN－注册２.png'),
          khImg: require('../../assets/image/ewallet/注册步骤-KH/KH-注册2.png'),
          topic: 'Step 2',
          text: '输入您的个人验证码'
        },
        {},
        {
          zhImg: require('../../assets/image/ewallet/注册步骤-CN/CN-注册3.png'),
          enImg: require('../../assets/image/ewallet/注册步骤- EN/EN-注册３.png'),
          khImg: require('../../assets/image/ewallet/注册步骤-KH/KH-注册3.png'),
          topic: 'Step 3',
          text: '成功进入UPAY'
        }
      ]
    },
    rechargeSteps() {
      return [
        {
          img: require('@/assets/image/default.png'),
          topic: 'Step 1',
          text: '至合作商户选择充值'
        },
        {},
        {
          img: require('@/assets/image/default.png'),
          topic: 'Step 2',
          text: '多功能终端机'
        }
      ]
    },
    inviteSteps() {
      return [
        {
          zhImg: require('../../assets/image/ewallet/邀请好友－CN/CN-邀请1.png'),
          enImg: require('../../assets/image/ewallet/邀请好友－EN/EN-邀请1.png'),
          khImg: require('../../assets/image/ewallet/邀请好友－KH/KH- Iinvite1.png'),
          topic: 'Step 1',
          text: '点击“邀请”'
        },
        {},
        {
          zhImg: require('../../assets/image/ewallet/邀请好友－CN/CN-邀请2.png'),
          enImg: require('../../assets/image/ewallet/邀请好友－EN/EN-邀请2.png'),
          khImg: require('../../assets/image/ewallet/邀请好友－KH/KH-邀请2.png'),
          topic: 'Step 2',
          text: '点击“立即分享给好友”'
        },
        {},
        {
          zhImg: require('../../assets/image/ewallet/邀请好友－CN/CN-邀请3.png'),
          enImg: require('../../assets/image/ewallet/邀请好友－EN/EN-邀请3.png'),
          khImg: require('../../assets/image/ewallet/邀请好友－KH/KH-邀请3.png'),
          topic: 'Step 3',
          text: '向您的朋友展示“二维码“，让他们扫描；或点击“分享”将邀请链接分享给您的朋友'
        }
      ]
    },
    paySteps() {
      return [
        {
          zhImg: require('@/assets/image/ewallet/pay-bill/BillerCN.png'),
          enImg: require('@/assets/image/ewallet/pay-bill/BillerEN.png'),
          khImg: require('@/assets/image/ewallet/pay-bill/BillerKH.png'),
          topic: 'Step 1',
          text: '选择付款公司'
        },
        {},
        {
          zhImg: require('@/assets/image/ewallet/pay-bill/TopupCN.png'),
          enImg: require('@/assets/image/ewallet/pay-bill/TopupEN.png'),
          khImg: require('@/assets/image/ewallet/pay-bill/TopupKH.png'),
          topic: 'Step 2',
          text: '输入客户编号'
        },
        {},
        {
          zhImg: require('@/assets/image/ewallet/pay-bill/PaymentCN.png'),
          enImg: require('@/assets/image/ewallet/pay-bill/PaymentEN.png'),
          khImg: require('@/assets/image/ewallet/pay-bill/PaymentKH.png'),
          topic: 'Step 3',
          text: '输入付款金额即完成缴费'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.e-wallet {
  .banner {
    height: 550px;
    background: url("../../assets/image/ewallet/banner@2x.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    color: #ffffff;
    .container {
      padding-top: 230px;
      padding-left: 110px;
      line-height: 1.3;
      strong {
        font-size: 48px;
        font-weight: 900;
      }
      p {
        margin-top: 10px;
        line-height: 40px;
        font-size: 20px;
        font-weight: 300;
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        padding: 80px 18px 0;
        text-align: center;
        strong {
          font-size: 36px;
        }
        p {
          font-size: 16px;
        }
      }
      height: 400px;
    }
  }
  .common {
    .download {
      margin-top: -59px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 15px;
        img {
          height: 34px;
          width: 120px;
        }
      }
    }
    .use-steps {
      display: flex;
      margin-top: 75px;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      font-size: 14px;
      li {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 300px;
        img {
          // height: 392px;
          width: 242px;
          margin-bottom: 50px;
        }
        strong {
          font-weight: bold;
          color: #333333;
        }
        p {
          margin-top: 12px;
          line-height: 1.3;
          color: #333333;
        }
        .arrow {
          text-align: center;
          img {
            height: 16px;
            width: 20px;
            margin-bottom: 90px;
          }
        }
      }
      li:nth-child(2n) {
        align-self: center;
        width: 108px;
      }
    }
    @media screen and (max-width: 1200px) {
      .download {
        margin-top: 0px;
      }
      li {
        margin-bottom: 60px;
      }
    }
    @media screen and (max-width: 768px) {
      /deep/.container {
        max-width: 600px;
        margin: auto;
      }
      .use-steps {
        margin-top: 20px !important;
        li {
          margin: 30px 0;
          .arrow {
            img {
              transform: rotate(90deg);
              margin-bottom: 0;
            }
          }
          &:nth-child(2n) {
            width: 300px;
          }
        }
      }
    }
  }
  .become {
    .use-steps {
      margin-top: 100px;
    }
  }
  .rechargesteps {
    background: #f7f7f7;
    &::after {
      background: #f7f7f7 !important;
    }
  }
  .become,
  .rechargesteps {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      z-index: 6;
      bottom: -20px;
      height: 40px;
      width: 40px;
      background: #fff;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }
  .instructions {
    padding-bottom: 100px;
    background: #f7f7f7;
    .main {
      max-width: 900px;
      margin: auto;
      margin-bottom: 20px;
      border: none;
      .title-text {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 1.5;
        padding: 13px 0;
      }
      /deep/ .el-collapse-item {
        background: #fff;
        border-radius: 6px;
      }
      /deep/.solution_text {
        padding: 0 30px 10px 20px;
        background: #fff;
        color: #333333;
        border-radius: 0 0 6px 6px;
        b {
          display: block;
          font-weight: bold;
          padding-top: 10px;
        }
      }
      /deep/.el-collapse-item__content {
        padding-bottom: 0;
      }
      /deep/.el-collapse-item__header {
        padding: 0 10px 0 20px;
        border-radius: 6px;
        border-bottom: none;
        background: #fff;
        height: unset;
      }
      /deep/.el-collapse-item__wrap {
        border-bottom: none;
        border-radius: 6px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .instructions {
      .main {
        /deep/.solution_text {
          padding: 0 10px 10px 10px;
        }
        /deep/.el-collapse-item__header {
          padding: 0 5px 0 10px;
        }
      }
    }
  }
}
</style>
